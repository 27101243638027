import React from 'react';
import './Spinner.css'; // Optional: CSS for the spinner

function SpinnerAnimation() {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  );
}

export default SpinnerAnimation;
