import React, { useState, useEffect } from "react";
import "./componentview.css";
import "bootstrap/dist/css/bootstrap.min.css";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "@material-ui/core/Button";
import { PieChart } from "react-minimal-pie-chart";
import { fetchData, downloadExcel } from "../services/apiServices";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { checkAuth } from "../services/authService";
import TopNavBar from "../components/topnav/TopNav";
import { API_BASE_URL } from "../services/config"

const AnalyticsPage = () => {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });
  checkAuth();

  const jsonData = [
    { name: "Patient Name", age: "Age", city: "Location" },
    { name: "Alice", age: 25, city: "Los Angeles" },
    { name: "Bob", age: 35, city: "Chicago" },
  ];

  const data = [
    { title: "Attempts Exhausted", value: 18, color: "#E38627" },
    { title: "Non-compliant", value: 12, color: "#C13C37" },
    { title: "Out of Network", value: 10, color: "#dc3545" },
    { title: "In Progress", value: 15, color: "#FFA07A" },
    { title: "Scheduled", value: 15, color: "#2E86AB" },
    { title: "Completed", value: 20, color: "#28a745" },
  ];

  const jsonToCsv = (json) => {
    const items = json.map((item) => {
      return Object.values(item).join(",");
    });
    return items.join("\n");
  };

  async function fetchFile(url, options) {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response;
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validateForm = (data) => {
    const errors = {};
    if (!formData.startDate) {
      errors.startDate = "Start date is required";
    }

    if (!formData.endDate) {
      errors.endDate = "End date is required";
    } else if (formData.endDate < formData.startDate) {
      errors.endDate = "End date must be greater than or equal than start date";
    }
    setErrors(errors);

    return errors;
  };

  const fetchExcel = async () => {
    const errors = validateForm(formData);
    console.log("errors---", errors);
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const fromDatePathParam = encodeURIComponent(formData.startDate);
    const toDatePathParam = encodeURIComponent(formData.endDate);
    const downloadUrl = `${API_BASE_URL}/${downloadExcel}/${fromDatePathParam}/${toDatePathParam}`;
    console.log("Download URL:", downloadUrl);

    try {
      const response = await fetchFile(downloadUrl, {
        method: "GET",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // No need for Content-Type in GET requests
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download Excel file");
      }

      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.setAttribute("download", "referral_data.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleDownload = () => {
    fetchExcel();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="">
      <TopNavBar title="Analytics" />
      {/* <h2 className="page-header headerStype header-style">Analytics</h2> */}
      <div className="card card-view-style">
        <h4 className="card-view-title">Export CSV</h4>
        <div className="row g-3">
          <div className="col-md-4">
            <label htmlFor="startDate" className="form-label">
              Start Date<span className="required-fields">*</span>
            </label>
            <input
              type="date"
              className={`form-control ${errors.startDate ? "is-invalid" : ""}`}
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              max={getCurrentDate()}
            />
            <div className="text-danger">{errors.startDate}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="endDate" className="form-label">
              End Date<span className="required-fields">*</span>
            </label>
            <input
              type="date"
              className={`form-control ${errors.endDate ? "is-invalid" : ""}`}
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              max={getCurrentDate()}
            />
            <div className="text-danger">{errors.endDate}</div>
          </div>
          <div
            className="col-md-4 d-flex justify-content-center align-items-center h-100"
            style={{ marginTop: "22px" }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgb(42, 104, 165)",
                color: "white",
                width: "60%",
                height: "50px",
              }}
              startIcon={<CloudDownloadIcon />}
              onClick={handleDownload}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </div>

      <div className="col-4">
        <div className="card">
          <h4 className="card-view-title">Patient Status</h4>
          <PieChart
            data={data}
            label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
            labelStyle={{
              fontSize: "5px",
              fontFamily: "sans-serif",
              fill: "white",
            }}
            radius={42}
            animate
            animationDuration={2000}
          />
          <div style={{ marginTop: "20px" }}>
            {data.map((entry, index) => (
              <span key={`item-${index}`} style={{ marginRight: "10px" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    backgroundColor: entry.color,
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                />
                {entry.title}
              </span>
            ))}
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default AnalyticsPage;
