import React, { useState, useEffect } from "react";

import Table from "../components/table/Table";

import customerList from "../assets/JsonData/patient_list.json";
import "./componentview.css";
import { Route, Link } from "react-router-dom";
import SearchResults from "./searchResults";
import DatePicker from "react-datepicker";
import usStates from "../assets/JsonData/states.json";
import { fetchData, All_Referal } from "../services/apiServices";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router-dom";

import { checkAuth } from "../services/authService";
import TopNavBar from "../components/topnav/TopNav";
import Loader from "../components/Loader/Loader";

const patientsTableHead = [
  "Entry Date",
  "Patient name",
  "Insurance",
  "Appointment Date",
  "Next Follow Up Date",
  "Status",
  "Entered By",
  "DOB",
  "Goal",
];

const isEditReferal = { id: 123, name: "join" };

const renderHead = (item, index) => (
  <th className="tHeader td-style" key={index}>
    {item}
  </th>
);

const Customers = () => {
  // const [dob, setDob] = useState("");
  const [data, setData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [urlParams, setUrlParams] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    dateOfBirth: "",
    state: "",
    status: "",
    nextFollowupDate: "",
    entryDate: "",
  });
  const history = useHistory();
  checkAuth();
  const renderBody = (item, index) => {
    const fullName = [
      item.patientName,
      item.middleName,
      item.lastname
    ].filter(Boolean).join(' ');

    return(
    <tr className="table-view-cell-style" key={index}>
      <td className="td-style">
        {item.entryDate == null ? "" : item.entryDate}
      </td>
      <td className="td-style">
        {" "}
        <Link
          to={`/newreferral/${item.id}`}
          onClick={() => navigateToUpdate(item)}
          state={isEditReferal}
        >
          {fullName}
        </Link>
      </td>
      <td className="td-style">{item.primaryInsurance}</td>
      <td className="td-style">
        {item.appointmentDate == null ? "" : item.appointmentDate}
      </td>
      <td className="td-style">
        {item.nextFollowupDate == null ? "" : item.nextFollowupDate}
      </td>
      <td className="td-style">{item.status == null ? "" : item.status}</td>
      <td className="td-style">
        {item.enteredBy == null ? "" : item.enteredBy}
      </td>
      <td className="td-style">{item.dateOfBirth == null ? "" : item.dateOfBirth}</td>
      <td className="td-style">Goal</td>
    </tr>
  );
  }

  const searchSubmit = (e) => {
    localStorage.setItem("searchData", JSON.stringify(formData));
    const queryParams = new URLSearchParams(formData).toString();
    setUrlParams(queryParams.replace(" ", ""));
  };

  const handleReset = () => {
    localStorage.removeItem('searchData');
    const resetFormData = Object.fromEntries(
      Object.keys(formData).map((key) => [key, ""])
    );
    setUrlParams("");
    setCurrentPage(1)
    setFormData(resetFormData);
  };

  const navigateToUpdate = (item) => {
    localStorage.setItem("patientInfo", JSON.stringify(item));
    history.push(`/newreferral/${item.id}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const resetClicked = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: "" });
  };

  const fetchDataFromApi = async (page) => {
    const storedData = localStorage.getItem("searchData");
    if (storedData != null && urlParams == '') {
      return
    }
    try {
      const fetchedData = await fetchData(
        `${All_Referal}?${urlParams}` + `&currentPage=${page}&currentPageCount=10`
      );
      const refLog = fetchedData.referralLogMasters
      setData(refLog);
      setTotalRecords(fetchedData.totalCount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      NotificationManager.error("Network error.", "Error!!");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDataFromApi(1);
  }, [urlParams]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
    const storedData = localStorage.getItem("searchData");
    if (storedData != null) {
      const newData = JSON.parse(storedData);
      setFormData(newData)
      const queryParams = new URLSearchParams(newData).toString();
      setData(null);
      setUrlParams(queryParams.replace(" ", ""));
    }
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  const handlePageNumber = (data) => {
    console.log(data);
    setCurrentPage(data);
    fetchDataFromApi(data);
  };

  useEffect(() => {
    setShowTable(true);
  }, [data]);

  return (
    <div>
      <TopNavBar title="Referral Tracker" />
      <div className="row">
        <div className="row">
          <form>
            <div className="col-12">
              <div className="card card-view-style">
                <h4 className="card-view-title">Search Criteria</h4>
                <div className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="firstName" className="form-label">
                      Patient Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="Enter First Name"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="entryDate" className="form-label">
                      Entry Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="entryDate"
                      name="entryDate"
                      value={formData.entryDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="middlename" className="form-label">
                      Status
                    </label>
                    <select
                      id="status"
                      className="form-select"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        -- Select Status --
                      </option>
                      <option value="1st Attempt">1st Attempt</option>
                      <option value="2nd Attempt">2nd Attempt</option>
                      <option value="3rd Attempt">3rd Attempt</option>
                      <option value="Attempts Exhausted">Attempts Exhausted</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Non-compliant">Non-compliant</option>
                      <option value="Out of Network">Out of Network</option>
                      <option value="Pending Auth">Pending Auth</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Text Sent">Text Sent</option>
                    </select>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "0px" }}>
                  <div className="col-md-4">
                    <label htmlFor="DOB" className="form-label">
                      DOB
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label
                      htmlFor="dob"
                      className="form-label"
                      id="nextFollowupDate"
                      name="nextFollowupDate"
                    >
                      Next Follow Up Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="nextFollowupDate"
                      name="nextFollowupDate"
                      value={formData.nextFollowupDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-select"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        -- Select State --
                      </option>
                      {usStates.map((state, index) => (
                        <option key={index}>{state}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className="col-md-12 text-center g-3"
                  style={{
                    alignContent: "center",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <button
                    style={{ width: "130px" }}
                    className="submit-btn mr-2"
                    type="button"
                    onClick={searchSubmit}
                  >
                    Search
                  </button>
                  <button
                    style={{ width: "130px", marginLeft: "20px" }}
                    className="submit-btn mr-2"
                    type="button"
                    onClick={handleReset}
                  >
                    RESET
                  </button>
                </div>
              </div>

              {/* <div className="row">
              <div className="col-12">
                <div className="card card-view-style">
                  <div className="">
                    <SearchResults> </SearchResults>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </form>
        </div>
        {data != null && showTable && (
          <div className="col-12 card card__body">
            <Table
              className="styled-table"
              limit="10"
              headData={patientsTableHead}
              onPageNumber={handlePageNumber}
              currentPage={currentPage}
              totalCount={totalRecords}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={data}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </div>
        )}
      </div>
      <Loader isLoading={isLoading} />
      <NotificationContainer />
    </div>
  );
};

export default Customers;
