import { API_BASE_URL } from "./config"
//const API_BASE_URL = 'https://entpsa-referrals.azurewebsites.net/api';
export const Add_Referal = 'referral/add-referral'
export const All_Referal = 'referral/all-referrals'
export const Update_Referal = 'referral/update-referral'
export const login = 'referral/login'
export const downloadExcel = 'referral/download-excel-xls'
export const patientInfo = 'referral/get-patient-information'

export const referralPractice = 'referral/practice/'
export const referralProvider = 'referral/refferingprovider/'


export const fetchData = async (endpoint, options) => {
  const response = await fetch(`${API_BASE_URL}/${endpoint}`, options);
  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }
  return response.json();
};

export const postData = async (endpoint, data, options) => {
  const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers if needed
    },
    body: JSON.stringify(data),
    ...options,
  });
  if (!response.ok) {
    throw new Error('Failed to post data');
  }
  return response.json();
};

export const putData = async (endpoint, data, options) => {
  const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      // Add any other headers if needed
    },
    body: JSON.stringify(data),
    ...options,
  });
  if (!response.ok) {
    throw new Error('Failed to post data');
  }
  return response.json();
};
