import React from 'react'

import { Route, Switch } from 'react-router-dom'

import Dashboard from '../pages/Dashboard'
import Customers from '../pages/Customers'
import LoginPage from '../pages/loginPage'
import AnalyticsPage from '../pages/Analytics'


import CreateEditNewPatient from '../pages/CreateNewPatient'


const Routes = () => {
    return (
        <Switch>
            {/* <Route path='/login' component={LoginPage}/> */}
            <Route path="/login" exact component={LoginPage} />
            <Route path="/" exact component={LoginPage} />
            <Route path='/dashboard' exact component={Dashboard}/>
            {/* <Route path='/customers' component={Customers}/> */}
            <Route path='/newreferral' component={CreateEditNewPatient}/>
            <Route path='/referrallog' component={Customers}/>
            <Route path='/analytics' component={AnalyticsPage}/>
        </Switch>
    )
}

export default Routes

// {
//     "display_name": "Patients",
//     "route": "/customers",
//     "icon": "bx bx-user-pin"
// },