import React, { useState, useEffect, useRef } from "react";
import "./componentview.css";
import Alert from "react-bootstrap/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { Form, Dropdown, Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import "./componentview.css";
import "react-datepicker/dist/react-datepicker.css";
import usStates from "../assets/JsonData/states.json";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  postData,
  Add_Referal,
  Update_Referal,
  putData,
} from "../services/apiServices";
import { useParams } from "react-router-dom";
import { checkAuth } from "../services/authService";
import TopNavBar from "../components/topnav/TopNav";
import { useHistory } from "react-router-dom";
import {
  fetchData,
  patientInfo,
  referralPractice,
  referralProvider,
} from "../services/apiServices";

import SpinnerAnimation from "../components/spinner/Spinner";

const CreateEditNewPatient = () => {
  const history = useHistory();
  const [dob, setDob] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [patientID, setPatientID] = useState(0);
  const { formDataString } = useParams();
  const [filteredItems, setFilteredItems] = useState([]);
  const [practiceItems, setPracticeItems] = useState([]);
  const [providerItems, setProviderItems] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [practiceDropdown, setPracticeDropdown] = useState(false);
  const [providerDropdown, setProviderDropdown] = useState(false);

  const [selectedPractice, setSelectedPractice] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(false);

  const isEditReferal = !(location.pathname == "/newreferral");
  checkAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    DOB: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    referralsource: "",
    referringproviderPCP: "",
    referringpractice: "",
    reasonforReferral: "",
    primaryInsurce: "",
    secondaryInsurce: "",
    authRefRequired: "",
    selfPay: "",
    appointmentScheduled: "",
    appointmentDate: "",
    location: "",
    appointmentTime: "",
    status: "",
    nextFollowUpDate: "",
    strUserNotes: "",
    enteredBy: localStorage.getItem("Admin_Name"),
    physicianLiason: "",
    referringProviderNpi: "",
    referringPracticeNpi: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    DOB: "",
    phoneNumber: "",
    referralsource: "",
    referringproviderPCP: "",
    referringpractice: "",
    reasonforReferral: "",
    authRefRequired: "",
    appointmentDate: "",
    appointmentTime: "",
    nextFollowUpDate: "",
    location: "",
    status: "",
  });
  const inputRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  const [notesState, setNotesState] = useState([{}]);
  const notesArray = [
    {
      date: "11/10/2021",
      notes: "Heart Problem",
    },
    {
      date: "11/04/2022",
      notes: "Problem Solved",
    },
    {
      date: "12/06/2023",
      notes: "Problem again started",
    },
  ];

  useEffect(() => {
    if (inputRef.current) {
      setDropdownWidth(inputRef.current.offsetWidth);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getPatientInfo = async (phone) => {
    if (phone.length <= 0) {
      setFilteredItems([]);
      return;
    }
    try {
      const fetchedData = await fetchData(
        `${patientInfo}?phoneNumber=${phone}`
      );
      setFilteredItems(fetchedData);
    } catch (error) {
      NotificationManager.error("Network error.", "Error!!");
    }
  };

  const getPracticeInfo = async (practice) => {
    if (practice.length <= 1) {
      setPracticeItems([]);
      return;
    }
    try {
      const fetchedData = await fetchData(`${referralPractice}${practice}`);
      setPracticeItems(fetchedData);
    } catch (error) {
      NotificationManager.error("Network error.", "Error!!");
    }
  };

  const getProviderInfo = async (provider) => {
    if (provider.length <= 1) {
      setProviderItems([]);
      return;
    }
    try {
      const fetchedData = await fetchData(`${referralProvider}${provider}`);
      setProviderItems(fetchedData);
    } catch (error) {
      NotificationManager.error("Network error.", "Error!!");
    }
  };

  useEffect(() => {
    if (isEditReferal) {
      setSelectedProvider(isEditReferal)
      setSelectedPractice(isEditReferal)
    }
    NotificationManager.listNotify.forEach((n) =>
      NotificationManager.remove({ id: n.id })
    );
    const storedData = localStorage.getItem("patientInfo");
    if (storedData != null) {
      const newData = JSON.parse(storedData);
      setPatientID(newData.id);
      const authRefRequiredString =
        newData.authReferralRequired !== undefined
          ? newData.authReferralRequired.toString()
          : "";
      setFormData((prevData) => ({
        ...prevData,
        firstName: newData.patientName || "",
        lastName: newData.lastname || "",
        middleName: newData.middleName || "",
        DOB: newData.dateOfBirth || "",
        phoneNumber: newData.phoneNumber || "",
        address1: newData.address1 || "",
        address2: newData.address2 || "",
        city: newData.city || "",
        state: newData.state || "",
        country: newData.country || "",
        zipcode: newData.zipCode || "",
        referralsource: newData.referralSource || "",
        referringproviderPCP: newData.referringProviderPcp || "",
        referringpractice: newData.referringPractice || "",
        reasonforReferral: newData.reasonForReferral || "",
        primaryInsurce: newData.primaryInsurance || "",
        secondaryInsurce: newData.secondaryInsurance || "",
        authRefRequired: authRefRequiredString || "",
        selfPay: newData.selfPay || "",
        appointmentScheduled: newData.appointmentScheduled || "",
        appointmentDate: newData.appointmentDate || "",
        location: newData.location || "",
        appointmentTime: newData.appointmentTime || "",
        status: newData.status || "",
        nextFollowUpDate: newData.nextFollowupDate || "",
        strUserNotes: newData.strUserNotes,
        enteredBy: localStorage.getItem("Admin_Name"),
        physicianLiason: newData.physicianLiason || "",
        referringProviderNpi: newData.referringProviderNpi || "",
        referringPracticeNpi: newData.referringPracticeNpi || "",
      }));
      setNotesState(newData.notes); // newData.noteData
      localStorage.removeItem("patientInfo");
    }
  }, []);

  const handleReset = () => {
    const resetFormData = Object.fromEntries(
      Object.keys(formData).map((key) => [key, ""])
    );
    setFormData(resetFormData);
  };

  useEffect(() => {
    if (!isEditReferal) {
      handleReset();
    }
  }, [isEditReferal]);

  const resetClicked = () => {
    handleReset();
  };

  const handleSubmitAddAnother = () => {
    validateSubmission(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateSubmission(false);
  };

  const showMsg = (msg, isError) => {
    if (isError === true) {
      NotificationManager.error(msg, "Error!!");
    } else {
      NotificationManager.success(msg, "Success!!");
    }
  };

  const validateSubmission = async (isAddAnother) => {
    setShowDropdown(false);
    setPracticeDropdown(false);
    setProviderDropdown(false);

    const newErrors = {};

    if (formData.firstName.trim() === "") {
      newErrors.firstName = "First name is required";
    }

    if (formData.lastName.trim() === "") {
      newErrors.lastName = "Last name is required";
    }

    if (formData.phoneNumber.trim() === "") {
      newErrors.phoneNumber = "Phone Number is required";
    }

    if (formData.referralsource.trim() === "") {
      newErrors.referralsource = "Referral Source is required";
    }

    // if (isEditReferal) {
    //   if (formData.referringproviderPCP.trim() === "") {
    //     newErrors.referringproviderPCP = "Referring Provider is invalid 555555";
    //   }
    // } else {
    //   if (formData.referringproviderPCP.trim() === "" || !selectedProvider) {
    //     newErrors.referringproviderPCP = "Referring Provider is invalid";
    //   }
    // }

    if (formData.referringproviderPCP.trim() === "" || !selectedProvider) {
      newErrors.referringproviderPCP = "Referring Provider is invalid";
    }

   

    if (formData.reasonforReferral.trim() === "") {
      newErrors.reasonforReferral = "Reason for Referral is required";
    }

    if (formData.referringpractice.trim() === "" || !selectedPractice) {
      newErrors.referringpractice = "Referral Practice is invalid";
    }

    if (formData.authRefRequired.trim() === "") {
      newErrors.authRefRequired = "Auth is required";
    }

    if (formData.DOB.trim() === "") {
      newErrors.DOB = "Date of birth is required";
    }

    if (
      formData.appointmentDate.trim() === "" &&
      formData.appointmentScheduled === "Yes"
    ) {
      newErrors.appointmentDate = "Appointment date is required";
    }

    // if (formData.appointmentDate.trim() === "") {
    //   newErrors.nextFollowUpDate = "Next follow up date is required";
    // }

    if (
      formData.status.trim() === "" &&
      formData.appointmentScheduled === "Yes"
    ) {
      newErrors.status = "Status is required";
    }

    // if (formData.location.trim() === "") {
    //   newErrors.location = "Location is required";
    // }

    // console.log("errorr--", formData, newErrors);

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      //setLoading(true);
      const authRef = formData.authRefRequired === "Yes" ? true : false;
      const patientAttributes = {
        enteredBy: localStorage.getItem("Admin_Name"),
        status: formData.status,
        authReferralRequired: formData.authRefRequired,
        location: formData.location,
        referralSource: formData.referralsource,
        patientName: formData.firstName,
        lastName: formData.lastName,
        middleName: formData.middleName,
        referringPractice: formData.referringpractice,
        referringProviderPcp: formData.referringproviderPCP,
        reasonForReferral: formData.reasonforReferral,
        insurance: formData.primaryInsurce,
        nextFollowupDate: formData.nextFollowUpDate,
        //scheduledBy:formData.scheduledBy, need to check attribute
        appointmentDate: formData.appointmentDate,
        dateOfBirth: formData.DOB,
        phoneNumber: formData.phoneNumber,
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        zipCode: formData.zipcode,
        primaryInsurance: formData.primaryInsurce,
        secondaryInsurance: formData.secondaryInsurce,
        selfPay: formData.selfPay,
        appointmentScheduled: formData.appointmentScheduled,
        appointmentTime: formData.appointmentTime,
        strUserNotes: formData.strUserNotes,
        physicianLiason: formData.physicianLiason,
        referringProviderNpi: formData.referringProviderNpi,
        referringPracticeNpi: formData.referringPracticeNpi,
      };

      if (isEditReferal) {
        patientAttributes.id = patientID;
      }
      const postDataEndPoint = isEditReferal ? Update_Referal : Add_Referal;
      try {
        setLoading(true);
        if (isEditReferal) {
          await putData(Update_Referal, patientAttributes);
          const msg = "Patient information has been updated successfully";
          showMsg(msg, false);
          // NotificationManager.success(msg, "Success!!");
        } else {
          await postData(Add_Referal, patientAttributes);
          const sub = "Patient information has been successfully submitted.";
          showMsg(sub, false);
          // NotificationManager.success(sub, "Success!!");
        }
        setTimeout(() => {
          handleReset();
        }, 2000);

        setTimeout(() => {
          setLoading(false);
          if (!isAddAnother) {
            // window.location.href = "/referrallog";
            history.push("/referrallog");
          }
        }, 2000);
      } catch (error) {
        // Handle error
        setLoading(false);
        showMsg("Network error.", true);
      }
    } else {
      showMsg("Kindly complete all mandatory fields.", true);
      // NotificationManager.error(
      //   "Kindly complete all mandatory fields.",
      //   "Error!!"
      // );
    }
  };

  const submitClicked = () => {};

  const handleFieldClick = (e) => {
    if (e.target.name === "authRefRequired") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    setProviderDropdown(false);
    setPracticeDropdown(false);
    setErrors((prevErrors) => ({
      ...prevErrors, // Clone the previous state object
      [e.target.name]: "", // Update the specific property
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name == "phoneNumber" && !isEditReferal) {
      setShowDropdown(true);
      getPatientInfo(value);
    } else if (e.target.name == "referringpractice") {
      setPracticeDropdown(true);
      getPracticeInfo(value);
      setSelectedPractice(false);
      setProviderDropdown(false)
    } else if (e.target.name == "referringproviderPCP") {
      setProviderDropdown(true);
      getProviderInfo(value);
      setSelectedProvider(false);
      setPracticeDropdown(false);

    } else {
      setShowDropdown(false);
      setPracticeDropdown(false);
      setProviderDropdown(false);
    }
    setFormData({ ...formData, [name]: value });
    if (e.target.name == "phoneNumber" || e.target.name == "zipcode") {
      const newValue = e.target.value.replace(/\D/g, "");
      setFormData({ ...formData, [name]: newValue });
    }
  };

  const handleOptionClick = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      firstName: newData.patientName.split(" ")[0] || "",
      lastName: newData.patientName.split(" ")[1] || "",
      middleName: newData.patientName.split(" ")[2] || "",
      DOB: newData.dateOfBirth || "",
      phoneNumber: newData.phoneNumber || "",
      address1: newData.address1 || "",
      address2: newData.address2 || "",
      city: newData.city || "",
      state: newData.state || "",
      country: newData.country || "",
      zipcode: newData.zipCode || "",
    }));

    setShowDropdown(false);
  };

  const handlePracticeClick = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      referringpractice: newData.ReferringPractice || "",
      physicianLiason: newData.physicianLiason || "",
      referringPracticeNpi: newData.referringPracticeNpi || "",
    }));

    setPracticeDropdown(false);
    setSelectedPractice(true);
  };

  const handleProviderClick = (newData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      referringproviderPCP: newData.ReferringProviderPcp || "",
      referringProviderNpi: newData.ReferringProviderNPI || "",
    }));

    setProviderDropdown(false);
    setSelectedProvider(true);
  };

  const handleClickOutside = (event) => {
    // if (showDropdown) {
    //   setShowDropdown(false);
    // }

    // if (!practiceDropdown) setPracticeDropdown(false);

    // if (!providerDropdown) setProviderDropdown(false);

    // setShowDropdown(false);
    // setPracticeDropdown(false);
    // setProviderDropdown(false);
  };

  return (
    <div>
      <TopNavBar title={isEditReferal ? "Update Referral" : "New Referral"} />
      {loading && <SpinnerAnimation />}
      <div className="row col-12 card card-view-style">
        <h4 className="card-view-title">Patient Demographics</h4>
        <div className="col-4 dropdown-search">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number<span className="required-fields">*</span>
          </label>
          <input
            ref={inputRef}
            type="tel"
            className={`form-control ${errors.phoneNumber ? "is-invalid" : ""}`}
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Enter Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            onClick={handleFieldClick}
            autoComplete="off"
          />
          {showDropdown && filteredItems.length > 0 && (
            <div
              style={{
                position: "absolute",
                width: dropdownWidth,
                // other styles like z-index, top, left, etc.
              }}
            >
              <ul className="dropdown">
                {filteredItems.map((option) => (
                  <li
                    key={option.patient_id}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.patientName} <br /> {option.phoneNumber}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="text-danger">{errors.phoneNumber}</div>
        </div>
        <div className="row" style={{ marginTop: "0px" }}>
          <div className="col-md-4">
            <label htmlFor="fname" className="form-label">
              First Name<span className="required-fields">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errors.firstName ? "is-invalid" : ""}`} //is-invalid
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter First Name"
            />
            <div className="text-danger">{errors.firstName}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="lastname" className="form-label">
              Last Name<span className="required-fields">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
              id="lastName"
              placeholder="Enter Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              onClick={handleFieldClick}
            />
            <div className="text-danger">{errors.lastName}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="middleName" className="form-label">
              Middle/Initial Name
            </label>
            <input
              type="text"
              className="form-control"
              id="middleName"
              name="middleName"
              placeholder="Enter Middle Name"
              value={formData.middleName}
              onChange={handleChange}
              onClick={handleFieldClick}
            />
          </div>

          <div className="col-4">
            <label htmlFor="DOB" className="form-label">
              DOB<span className="required-fields">*</span>
            </label>
            <input
              type="date"
              className={`form-control ${errors.DOB ? "is-invalid" : ""}`}
              id="DOB"
              name="DOB"
              value={formData.DOB}
              onChange={handleChange}
              onClick={handleFieldClick}
            />
            <div className="text-danger">{errors.DOB}</div>
            {/* <DatePicker style={{width: '100%'}}
                    showIcon
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    className="form-control"
                    dateFormat="MM/dd/yyyy" 
                    showYearDropdown 
                    showMonthDropdown
                    scrollableYearDropdown 
                    yearDropdownItemNumber={100}
                    placeholderText="MM/dd/yyyy"
                  /> */}
          </div>
          {/* <div className="col-4">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number<span className="required-fields">*</span>
            </label>
            <input
              type="tel"
              className={`form-control ${
                errors.phoneNumber ? "is-invalid" : ""
              }`}
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              onClick={handleFieldClick}
            />
            <div className="text-danger">{errors.phoneNumber}</div>
          </div> */}
          <div className="col-md-6">
            <label htmlFor="address1" className="form-label">
              Address line 1
            </label>
            <input
              type="text"
              className="form-control"
              id="address1"
              name="address1"
              value={formData.address1}
              placeholder="Enter Address line 1"
              onChange={handleChange}
              onClick={handleFieldClick}
            />
            <div className="text-danger">{errors.address1}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="address2" className="form-label">
              Address line 2
            </label>
            <input
              type="text"
              className="form-control"
              id="address2"
              name="address2"
              value={formData.address2}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter Address line 2"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              placeholder="Enter City"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="state" className="form-label">
              State
            </label>

            <select
              id="state"
              name="state"
              value={formData.state}
              className="form-control"
              onChange={handleChange}
              onClick={handleFieldClick}
            >
              <option value="" disabled>
                -- Select State --
              </option>
              {usStates.map((state, index) => (
                <option key={index}>{state}</option>
              ))}
            </select>
            <div className="text-danger">{errors.state}</div>
          </div>
          <div className="col-md-4">
            <label htmlFor="county" className="form-label">
              County
            </label>
            <input
              type="text"
              className="form-control"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              placeholder="Enter County"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="zipcode" className="form-label">
              Zip code
            </label>
            <input
              type="text"
              className="form-control"
              id="zipcode"
              name="zipcode"
              value={formData.zipcode}
              onClick={handleFieldClick}
              onChange={handleChange}
              placeholder="Enter Zip Code"
            />
            <div className="text-danger">{errors.zipcode}</div>
          </div>
        </div>
      </div>
      <div className="row col-12 card card-view-style">
        <h4 className="card-view-title">Referral information</h4>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="referralsource" className="form-label">
              Referral Source<span className="required-fields">*</span>
            </label>
            <select
              id="referralsource"
              name="referralsource"
              value={formData.referralsource}
              className={`form-select ${
                errors.referralsource ? "is-invalid" : ""
              }`}
              onChange={handleChange}
              onClick={handleFieldClick}
            >
              <option value="" disabled>
                -- Select Referral Source --
              </option>
              <option value="Advent" selected>
                Advent
              </option>
              <option value="Billboard">Billboard</option>
              <option value="Brevium">Brevium</option>
              <option value="Online">Online</option>
              <option value="Orlando Health">Orlando Health</option>

              <option value="Other Provider">Other Provider</option>
              <option value="Patient Referral">Patient Referral</option>
            </select>
            <div className="text-danger">{errors.referralsource}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="reasonforReferral" className="form-label">
              Reason for Referral
              <span className="required-fields">*</span>
            </label>
            <select
              id="reasonforReferral"
              name="reasonforReferral"
              value={formData.reasonforReferral}
              className={`form-select ${
                errors.reasonforReferral ? "is-invalid" : ""
              }`}
              onChange={handleChange}
              onClick={handleFieldClick}
            >
              <option value="" disabled>
                --Select reason for referral--
              </option>
              <option value="Hearing Loss" selected>
                Hearing Loss
              </option>
              <option value="Tinnitus" selected>
                Tinnitus
              </option>
              <option value="Ear" selected>
                Ear
              </option>
              <option value="Nose" selected>
                Nose
              </option>
              <option value="Throat" selected>
                Throat
              </option>
              <option value="Inspire/OSA" selected>
                Inspire/OSA
              </option>
              <option value="Sinus" selected>
                Sinus
              </option>
              <option value="Allergy" selected>
                Allergy
              </option>
              <option value="Cancer" selected>
                Cancer
              </option>
              <option value="Dizziness" selected>
                Dizziness
              </option>
              <option value="Pediatrics" selected>
                Pediatrics
              </option>
              
            </select>
            <div className="text-danger">{errors.reasonforReferral}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="referringpractice" className="form-label">
              Referring Practice
              <span className="required-fields">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.referringpractice ? "is-invalid" : ""
              }`}
              id="referringpractice"
              name="referringpractice"
              value={formData.referringpractice}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter Referring Practice"
              ref={inputRef}
            />
            {practiceDropdown && practiceItems.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  width: dropdownWidth,
                  // other styles like z-index, top, left, etc.
                }}
              >
                <ul className="dropdown">
                  {practiceItems.map((option) => (
                    <li
                      key={option.id}
                      onClick={() => handlePracticeClick(option)}
                    >
                      {option.ReferringPractice} <br />{" "}
                      {option.ReferringPracticeNPI || ""}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="text-danger">{errors.referringpractice}</div>
          </div>
          <div className="col-md-6">
            <label
              htmlFor="referringpractice"
              className="form-label disabled-label"
            >
              Referring Practice NPI
            </label>
            <input
              type="text"
              className={`form-control`}
              id="referringpractice"
              name="referringpractice"
              value={formData.referringPracticeNpi}
              disabled
            />
          </div>
          <div className="col-md-6 row" style={{ marginLeft: "0px" }}>
            <label
              style={{ marginLeft: "-12px" }}
              htmlFor="referringpractice"
              className="form-label disabled-label"
            >
              Physician Liason
            </label>
            <input
              type="text"
              className={`form-control`}
              id="referringpractice"
              name="referringpractice"
              value={formData.physicianLiason}
              disabled
            />
          </div>
          <div className="col-md-6 row"></div>

          <div className="col-md-6">
            <label htmlFor="referringproviderPCP" className="form-label">
              Referring Provider PCP
              <span className="required-fields">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.referringproviderPCP ? "is-invalid" : ""
              }`}
              id="referringproviderPCP"
              name="referringproviderPCP"
              value={formData.referringproviderPCP}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter Referring Provider"
              ref={inputRef}
            />
            {providerDropdown && providerItems.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  width: dropdownWidth,
                  // other styles like z-index, top, left, etc.
                }}
              >
                <ul className="dropdown">
                  {providerItems.map((provider) => (
                    <li
                      key={provider.id}
                      onClick={() => handleProviderClick(provider)}
                    >
                      {provider.ReferringProviderPcp} <br />{" "}
                      {provider.ReferringProviderNPI}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="text-danger">{errors.referringproviderPCP}</div>
          </div>
          <div className="col-md-6">
            <label
              htmlFor="referringproviderPCP"
              className="form-label disabled-label"
            >
              Referring Provider NPI
            </label>
            <input
              type="text"
              className={`form-control`}
              id="referringproviderPCP"
              name="referringproviderPCP"
              value={formData.referringProviderNpi}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="row col-12 card card-view-style">
        <h4 className="card-view-title">Insurance info</h4>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="primaryInsurce" className="form-label">
              Primary Insurance{" "}
            </label>
            <input
              type="text"
              className="form-control"
              id="primaryInsurce"
              name="primaryInsurce"
              value={formData.primaryInsurce}
              onChange={handleChange}
              placeholder="Enter Primary insurance"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="fname" className="form-label">
              Auth / Referral required ?
              <span className="required-fields">*</span>{" "}
            </label>
            <div className="form-check form-check-inline check-box-style">
              <input
                className="form-check-input"
                type="radio"
                id="authRefRequired"
                name="authRefRequired"
                checked={formData.authRefRequired === "true"}
                value="true"
                onChange={handleChange}
                onClick={handleFieldClick}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                YES
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="authRefRequired"
                name="authRefRequired"
                value="false"
                checked={formData.authRefRequired === "false"}
                onChange={handleChange}
                onClick={handleFieldClick}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                NO
              </label>
            </div>
            <div className="text-danger">{errors.authRefRequired}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="secondaryInsurce" className="form-label">
              Secondary Insurance{" "}
            </label>
            <input
              type="text"
              className="form-control"
              id="secondaryInsurce"
              name="secondaryInsurce"
              value={formData.secondaryInsurce}
              onChange={handleChange}
              placeholder="Enter Secondary insurance Provider"
            />
          </div>
          <div className="row-md-6" style={{ paddingTop: "20px" }}>
            <label htmlFor="selfPay" className="form-label">
              Self Pay
            </label>
            <div className="form-check form-check-inline check-box-style">
              <input
                className="form-check-input"
                type="radio"
                id="selfPay"
                name="selfPay"
                value="Yes"
                checked={formData.selfPay === "Yes"}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                YES
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="selfPay"
                name="selfPay"
                checked={formData.selfPay === "No"}
                value="No"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                NO
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row col-12 card card-view-style">
        <h4 className="card-view-title">Appoinment info </h4>
        <div className="row">
          <div className="row-md-6">
            <label htmlFor="appointmentScheduled" className="form-label">
              Appointment scheduled ?
            </label>
            <div className="form-check form-check-inline check-box-style">
              <input
                className="form-check-input"
                type="radio"
                id="appointmentScheduled"
                name="appointmentScheduled"
                checked={formData.appointmentScheduled === "Yes"}
                value="Yes"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                YES
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="appointmentScheduled"
                name="appointmentScheduled"
                checked={formData.appointmentScheduled === "No"}
                value="No"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                NO
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="appointmentDate" className="form-label">
              Appointment Date
              <span className="required-fields">
                {formData.appointmentScheduled === "Yes" ? "*" : ""}
              </span>
            </label>
            <input
              type="date"
              className={`form-control ${
                errors.appointmentDate ? "is-invalid" : ""
              }`}
              id="appointmentDate"
              name="appointmentDate"
              value={formData.appointmentDate}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter Appointment Date"
            />
            <div className="text-danger">{errors.appointmentDate}</div>
          </div>
          <div className="col-md-6">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <select
              id="location"
              name="location"
              value={formData.location}
              className={`form-select`}
              onChange={handleChange}
              onClick={handleFieldClick}
            >
              <option value="" disabled>
                -- Select Location --
              </option>
              <option value="WP - Winter Park">WP - Winter Park</option>
              <option value="CEL - Celebration">CEL - Celebration</option>
              <option value="MICH - Michigan">MICH - Michigan</option>
              <option value="ALT - Altamonte">ALT - Altamonte</option>
              <option value="WG - Winter Garden">WG - Winter Garden</option>
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="appointmentTime" className="form-label">
              Appointment Time
            </label>
            <input
              type="time"
              className="form-control"
              id="appointmentTime"
              name="appointmentTime"
              value={formData.appointmentTime}
              onChange={handleChange}
              placeholder="Enter Appointment Time"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="location" className="form-label">
              Status
              <span className="required-fields">
                {formData.appointmentScheduled === "Yes" ? "*" : ""}
              </span>
            </label>
            <select
              id="status"
              name="status"
              value={formData.status}
              className={`form-select ${errors.status ? "is-invalid" : ""}`}
              onChange={handleChange}
              onClick={handleFieldClick}
            >
              <option value="" disabled>
                -- Select Status --
              </option>
              <option value="1st Attempt">1st Attempt</option>
              <option value="2nd Attempt">2nd Attempt</option>
              <option value="3rd Attempt">3rd Attempt</option>
              <option value="Attempts Exhausted">Attempts Exhausted</option>
              <option value="In Progress">In Progress</option>
              <option value="Non-compliant">Non-compliant</option>
              <option value="Out of Network">Out of Network</option>
              <option value="Pending Auth">Pending Auth</option>
              <option value="Scheduled">Scheduled</option>
              <option value="Text Sent">Text Sent</option>
            </select>
            <div className="text-danger">{errors.status}</div>
          </div>
          {/* <div className="col-md-6">
              <label htmlFor="status" className="form-label">
                Status<span className="required-fields">*</span>
              </label>
              <select
                id="status"
                name="status"
                className={`form-select ${errors.status ? "is-invalid" : ""}`}
                onChange={handleChange}
                onClick={handleFieldClick}
              >
                <option value=""> -- Select Status -- </option>
                <option value="Attempts Exhausted">Attempts Exhausted</option>
                <option value="In Progress">In Progress</option>
                <option value="Non-compliant">Non-compliant</option>
                <option value="Out of Network">Out of Network </option>
                <option value="Scheduled">Scheduled </option>
              </select>
              <div className="text-danger">{errors.status}</div>
            </div> */}
        </div>
      </div>
      <div className="row col-12 card card-view-style">
        <h4 className="card-view-title">
          {isEditReferal ? "Notes History" : "Notes"}
        </h4>
        {isEditReferal && (
          <div className="col-md-6">
            <label htmlFor="nextFollowUpDate" className="form-label">
              Next Follow Up Date
            </label>
            <input
              type="date"
              className={`form-control`}
              id="nextFollowUpDate"
              name="nextFollowUpDate"
              value={formData.nextFollowUpDate}
              onChange={handleChange}
              onClick={handleFieldClick}
              placeholder="Enter Appointment Date"
            />
            {/* <div className="text-danger">{errors.nextFollowUpDate}</div> */}
          </div>
        )}

        {isEditReferal ? (
          <div>
            {" "}
            <Table striped bordered>
              <thead>
                <tr>
                  <th className="td-style">Entered Date</th>
                  <th className="td-style">Notes</th>
                </tr>
              </thead>
              <tbody>
                {notesState.map((note, index) => (
                  <tr key={index}>
                    <td className="td-style">{note.entryDate}</td>
                    <td className="td-style">{note.userNotes}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <textarea
              style={{ marginTop: "35px" }}
              className="form-control"
              id="strUserNotes"
              name="strUserNotes"
              value={formData.strUserNotes}
              onChange={handleChange}
              placeholder="Enter Notes here"
            ></textarea>
          </div>
        ) : (
          <div className="col-md-12">
            <div className="col-md-6">
              <label htmlFor="nextFollowUpDate" className="form-label">
                Next Follow Up Date
              </label>
              <input
                type="date"
                className={`form-control`}
                id="nextFollowUpDate"
                name="nextFollowUpDate"
                value={formData.nextFollowUpDate}
                onChange={handleChange}
                onClick={handleFieldClick}
                placeholder="Enter Appointment Date"
              />
            </div>
            <div className="col-md-12" style={{ marginTop: "20px" }}>
              <textarea
                className="form-control"
                id="strUserNotes"
                name="strUserNotes"
                value={formData.strUserNotes}
                onChange={handleChange}
                placeholder="Enter Notes here"
              ></textarea>
            </div>
          </div>
        )}
      </div>

      <div className="d-grid gap-3 d-md-flex justify-content-md-center">
        <button className="submit-btn" id="button1" onClick={handleSubmit}>
          &nbsp;&nbsp;&nbsp;{isEditReferal ? "UPDATE" : "  SUBMIT  "}
          &nbsp;&nbsp;&nbsp;
        </button>
        {/* <button type="submit" className="submit-btn" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...&nbsp;&nbsp;&nbsp;
        </button> */}

        <button className="submit-btn" id="button2" onClick={resetClicked}>
          &nbsp;&nbsp;&nbsp;{isEditReferal ? "RESET" : "RESET"}
          &nbsp;&nbsp;&nbsp;
        </button>

        {!isEditReferal && (
          <button
            className="submit-btn"
            id="button3"
            onClick={handleSubmitAddAnother}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;SUBMIT & ADD ANOTHER
            RECORD&nbsp;&nbsp;&nbsp;
          </button>
        )}
      </div>
      <NotificationContainer />
    </div>
  );
};

export default CreateEditNewPatient;
