import React from "react";
import "./topnav.css"; // Import CSS file for styling
import person from "../../assets/images/person.png";
const TopNavBar = ({ title }) => {
  return (
    <div className="top-nav-bar">
      <div className="title-Bar">{title}</div>
      <div className="user-info">
        <img src={person} alt="User Avatar" className="avatar" />
        <span className="username">{localStorage.getItem("Admin_Name")}</span>
      </div>
    </div>
  );
};

export default TopNavBar;
