import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

import StatusCard from "../components/status-card/StatusCard";

import Table from "../components/table/Table";

import Badge from "../components/badge/Badge";

import statusCards from "../assets/JsonData/status-card-data.json";
import { PieChart } from "react-minimal-pie-chart";
import SessionTimeout from "../utils/SessionTimeout";

import { logout, checkAuth } from "../services/authService";
import TopNavBar from "../components/topnav/TopNav"
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);
  const history = useHistory();

  // const handleLogout = () => {
  //   console.log("User logged out");
  //   // window.location.href = "/login";
  //   history.push("/login");
  //   localStorage.clear();
  //   logout();
  // };
  checkAuth();

  return (
    <div>
      <TopNavBar title="Dashboard"/>
      {/* <SessionTimeout onLogout={handleLogout} /> */}
      {/* <h2 className="page-header headerStype header-style">Dashboard</h2> */}
      <div className="row">
        <div className="col-6">
          <div className="card">
            <h4 className="chat-name-title">Number of referral</h4>
            <h3 className="chat-name-title">2568</h3>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <h4 className="chat-name-title">Appt Scheduled Vs Unscheduled</h4>
            <h3 className="chat-name-title">756 / 800</h3>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card">
            <h4 className="chat-name-title">
              Referral received over this month
            </h4>
            <h3 className="chat-name-title">1050</h3>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <h4 className="chat-name-title">Appointment scheduling summary</h4>
            <h3 className="chat-name-title">25</h3>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="card">
            <h4 className="chat-name-title">Appointment Tat summary</h4>
            <h3 className="chat-name-title">562</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
