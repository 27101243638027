import React, { useEffect, useRef } from 'react';

const SessionTimeout = ({ onLogout }) => {
  const timerRef = useRef(null);

  // Function to reset the logout timer
  const resetLogoutTimer = () => {
    if (timerRef.current) {
      console.log('resetting....')
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onLogout, 15 * 60 * 1000); // 15 minutes timeout
  };

  // Event listener to reset the logout timer on user activity
  const handleUserActivity = () => {
    resetLogoutTimer();
  };

  useEffect(() => {
    // Start the logout timer when the component mounts
    resetLogoutTimer();

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    // Clean up event listeners when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, []); // Only run once on component mount

  return null; // No UI for this component
};

export default SessionTimeout;
