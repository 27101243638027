import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({ isLoading }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
        {isLoading && <CircularProgress color="primary" />}
      </div>
    );
  };
  
  export default Loader;
  