import React from 'react'

import { Link } from 'react-router-dom'

import './sidebar.css'

import logo from '../../assets/images/ENT_logo.png'

import sidebar_items from '../../assets/JsonData/sidebar_routes.json'
import { logout } from "../../services/authService";
import { useHistory } from "react-router-dom";


const SidebarItem = props => {

    const active = props.active ? 'active' : ''

    return (
        <div className="sidebar__item">
            <div className={`sidebar__item-inner ${active}`}>
                <i className={props.icon}></i>
                <span>
                    {props.title}
                </span>
            </div>
        </div>
    )
}



const Sidebar = props => {
    const activeItem = sidebar_items.findIndex(item => item.route === props.location.pathname)
    const history = useHistory();

    const itemClick = (item) => {
        if(item.display_name == "Logout") {
            localStorage.clear()
            history.push("/login");
            logout()
        }
    }
    return (
        <div className='sidebar'>
            <div className="sidebar__logo">
                <img src={logo} alt="company logo" />
            </div>
            {
                sidebar_items.map((item, index) => (
                    <Link onClick={() => itemClick(item)} className="link-info link-underline-opacity-0" to={item.route} key={index}>
                        <SidebarItem
                            title={item.display_name}
                            icon={item.icon}
                            active={index === activeItem}
                        />
                    </Link>
                ))
            }
        </div>
    )
}

export default Sidebar
