import React, { useEffect } from 'react'

import './layout.css'

import Sidebar from '../sidebar/Sidebar'
import TopNav from '../topnav/TopNav'
import Routes from '../Routes'

import { BrowserRouter, Route } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import ThemeAction from '../../redux/actions/ThemeAction'
import LoginPage from '../../pages/loginPage'

import { logout } from "../../services/authService";
import SessionTimeout from "../../utils/SessionTimeout";

const Layout = () => {

    const handleLogout = () => {
        console.log("User logged out");
        window.location.href = "/login";
        localStorage.clear();
        logout();
      };


    const themeReducer = useSelector(state => state.ThemeReducer)

    const dispatch = useDispatch()
    const login = '/login'
    const initialRoute = '/'


    useEffect(() => {
        const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')

        const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')

        dispatch(ThemeAction.setMode(themeClass))

        dispatch(ThemeAction.setColor(colorClass))
    }, [dispatch])

    return (
        <BrowserRouter>
            <Route render={(props) => (
                <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
                    {
                        props.location.pathname === login || props.location.pathname === initialRoute ? <LoginPage /> : <div className="layout__content">
                            <Sidebar {...props} />
                            {/* <TopNav/> */}
                            <div className="layout__content-main">
                            <SessionTimeout onLogout={handleLogout} />

                                <Routes />
                            </div>
                        </div>
                    }


                </div>
            )} />
        </BrowserRouter>
    )
}

export default Layout



// <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
                //     <Sidebar {...props}/>
                //     <div className="layout__content">
                //         <div className="layout__content-main">
                //             <Routes/>
                //         </div>
                //     </div>
                // </div>