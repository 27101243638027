import React, { useState, useEffect } from "react";
import "./componentview.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/ENT_logo.png";
import { postData, login } from "../services/apiServices";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { loginAuth } from "../services/authService";
import mslogo from "../assets/images/msalogo.png";

const LoginPage = () => {
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const auth = await loginAuth();
      if (auth.length > 0) {
        NotificationManager.error("Try Again", "Login Failed!!");
        return
      }
      if(auth.account.name) {
        localStorage.setItem('Admin_Name', auth.account.name);
        history.push("/dashboard");
      } else {
        NotificationManager.error('Unable to get user information', "Login Failed!!");
      }
    } catch (e) {
      NotificationManager.error(e, "Login Failed!!");
      console.log(e);
    }
  };

  return (
    <div className="Auth-form-container">
      <div
        className="Auth-form"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="form-group mt-6">
          <img className="img-style" src={logo} alt="company logo" />
        </div>
        <button
          onClick={handleLogin}
          type="submit"
          className="submit-btn"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={mslogo}
            alt="company logo"
            style={{ width: "20px", marginLeft: "15px", marginRight: "8px" }}
          />
          Login with Microsoft Azure
        </button>
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <p className="copy-righ-text">
            Copyright Irongate Clinical Partners LLC
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
