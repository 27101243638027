// authService.js
import { PublicClientApplication } from '@azure/msal-browser';
import { CLIENTID,AUTHORITY,REDIRECTURI } from './config';
const msalConfig = {
  auth: {
    clientId: CLIENTID,
    authority: AUTHORITY,
    redirectUri: REDIRECTURI,
  },
};

// const pca = new PublicClientApplication(msalConfig);

let pca = null; // Declare PCA instance globally

export const initializeAuth = async () => {
  pca = new PublicClientApplication(msalConfig);
  await pca.initialize()
  await pca.handleRedirectPromise(); // Handle redirect after login
};

export const loginAuth = async () => {

  if (!pca) {
    throw new Error('Public Client Application is not initialized');
  }

  const loginRequest = {
    scopes: ['user.read'],
  };

  try {
    console.log(`${process.env.AZURE_AUTHORITY}`);

    return await pca.loginPopup(loginRequest)
    // const loginInfo = await pca.loginPopup(loginRequest);
    // console.log('AUTH LOGIN SUCCESS', loginInfo)
    // console.log(loginAuth.account.name)
  } catch (error) {
    console.log('AUTH LOGIN FAIL', error)
    return error
  }
};

export const logout = () => {
  if (!pca) {
    throw new Error('Public Client Application is not initialized');
  }
  pca.logout();
};

export const getToken = async () => {
  if (!pca) {
    throw new Error('Public Client Application is not initialized');
  }

  const tokenRequest = {
    scopes: ['user.read'],
  };

  try {
    const response = await pca.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const checkAuth = async () => {
  const accounts = await pca.getAllAccounts();
  if (accounts.length === 0) {
    // User is not authenticated, redirect to login page
    window.location.href = "/login";
  }
};

// Call initializeAuth and wait for it to complete before exporting other functions
(async () => {
  try {
    await initializeAuth();
  } catch (error) {
    console.error('Error initializing authentication:', error);
  }
})();